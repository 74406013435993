<script setup>
  // use compact for smaller hitbox (when links are close together)
  const { to, line, compact, disable } = defineProps({
    to: String,
    line: { type: String, default: '0.125' },
    compact: Boolean,
    disable: { type: Boolean, default: true },
  });
  const target = useLinkTarget(to);
</script>

<!----------------------------------------------------------------------------->

<template>
  <NuxtLink :to="to" :target="target" :class="{ compact, disable }" :style="{ '--line': `${line}rem` }"
    ><span><slot /></span
  ></NuxtLink>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  a {
    position: relative;
    white-space: nowrap;

    span {
      display: inline-block;
      margin-top: 0.15em;
    }

    &:after {
      content: '';

      position: absolute;
      margin-top: -0.1em;

      width: 100%;
      height: var(--line);

      background-color: currentColor;
      display: none;
    }

    @include hover {
      &:after {
        display: block;
      }
    }

    &.disable.router-link-active {
      color: $white-40;
      pointer-events: none;
    }
  }
</style>
