<script setup>
  const store = useBaseStore();
  const { mainFooter, secondaryFooter, bottomFooter } = store.getFooter;

  const { newsletterOverlayActive } = useNewsletterOverlay();

  const year = new Date().getFullYear();

  import { smoothie } from '~/composables/pinkyellow/useSmoothie';

  // --

  const { backToTopActive } = useBackToTop();
</script>

<!----------------------------------------------------------------------------->

<template>
  <footer>
    <ShowAnimation fadeOnly>
      <BackToTop v-if="backToTopActive" class="back-to-top" @click="smoothie._finish()" />
      <nav class="top">
        <Grid>
          <GridItem :from="1" :to="3" class="logo">
            <Logo />
          </GridItem>
          <GridItem :from="4" :to="5" class="primary">
            <ul>
              <li v-for="link in mainFooter">
                <NavLink :to="link.page.url">{{ link.page.title }}</NavLink>
              </li>
            </ul>
          </GridItem>
          <GridItem :from="6" :to="8" class="secondary">
            <ul>
              <li v-for="link in secondaryFooter">
                <NavLink :to="link.page.url" line="0.1" compact>{{ link.page.title }}</NavLink>
              </li>
            </ul>
          </GridItem>
          <GridItem :from="9" :to="12" class="newsletter">
            <h2>Join<br />the Glory<br />Newsletter</h2>
            <Button to="javascript:;" @click="newsletterOverlayActive = true">Sign up</Button>
          </GridItem>
          <Socials class="socials" />
        </Grid>
      </nav>
      <nav class="bottom">
        <Wrapper class="wrapper">
          <ul>
            <li v-for="link in bottomFooter">
              <NavLink :to="link.page.url" line="0.1" :disable="false">{{ link.page.title }}</NavLink>
            </li>
          </ul>
          <span class="copyright">© {{ year }} GLORY Kickboxing. All Rights Reserved.</span>
        </Wrapper>
      </nav>
    </ShowAnimation>
  </footer>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  footer {
    position: relative;
    overflow: hidden;
    margin-top: auto;

    color: $white;
    background-color: $black;

    // ::selection {
    //   background-color: $white;
    //   color: $black;
    // }

    @include industry-demi(px(12));
  }

  .back-to-top {
    position: absolute;

    top: px(32);
    right: px(80);

    @include break-medium {
      right: px(64);
    }

    @include break-small {
      right: px(48);
    }
  }

  .top {
    background-color: $black;
    padding: px(96) 0 px(42);

    .primary,
    .secondary {
      ul {
        display: flex;
        flex-direction: column;
        gap: px(12);
      }
    }

    .primary {
      font-size: px(20);
      margin-top: -0.5em;
    }

    .secondary {
      margin-top: -0.45em;
    }

    .newsletter {
      margin-top: px(4);
      a {
        margin-top: px(62);
      }
    }

    .socials {
      margin-top: px(169);
    }
  }

  // --

  .bottom {
    height: px(58);

    display: flex;
    align-items: center;

    color: $grey-2;
    background-color: $black-3;

    .wrapper {
      display: flex;
      justify-content: space-between;
    }

    ul {
      display: flex;
      gap: px(54);

      margin-top: 0.1em;
    }

    .copyright {
      @include roboto-regular(px(11));
      margin-top: 0.15em;
    }
  }

  @include break-large {
    @include break-min-small {
      .top {
        padding-top: px(68);

        .logo {
          @include column(1, 12);
          padding-bottom: px(92);
        }
        .primary {
          @include column(1, 2);

          font-size: px(18);
        }

        .secondary {
          @include column(4, 6);
          font-size: px(11);

          @include break-medium {
            font-size: px(10);
          }
        }

        .newsletter {
          @include column(7, 12);
          h2 {
            font-size: 10vw;
          }
        }
      }

      .bottom {
        font-size: px(11);
        ul {
          gap: 3vw;
        }

        @include break-medium {
          font-size: px(10);

          ul {
            gap: 2.5vw;
          }
        }
      }
    }
  }

  @include break-small {
    .top {
      padding-top: px(68);
      padding-bottom: px(58);

      :deep(.grid) {
        display: grid;
      }

      .logo {
        grid-row: 1;
        @include column(1, 12);

        margin-top: 0;
      }

      .newsletter {
        grid-row: 2;
        @include column(1, 12);

        margin-top: px(32);

        h2 {
          font-size: 16vw;

          @include break-phone {
            font-size: px(80);
          }
        }

        a {
          margin-top: px(24);
        }
      }

      .primary {
        grid-row: 3;
        @include column(1, 12);

        margin-top: px(50);
      }

      .secondary {
        grid-row: 4;
        @include column(1, 12);

        margin-top: px(44);
        font-size: px(12);
      }

      .socials {
        margin-top: px(58);
      }
    }

    .bottom {
      height: auto;
      padding: px(18) 0 px(48);

      .wrapper {
        flex-direction: column;
        gap: px(42);
      }

      ul {
        flex-direction: column;
        gap: px(13);
      }
    }
  }
</style>
