<template>
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.48649 1.87747L1.18198 6.18198L0.474874 5.47487L4.77939 1.17036L0.819588 1.17036L0.828427 0.171573H6.48528V5.82843L5.48649 5.83727L5.48649 1.87747Z"
      fill="white"
    />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(7);
  }
</style>
