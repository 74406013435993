<script setup>
  const { searchOverlayActive } = useSearchOverlay();

  // --

  const STICKY_TOP = 100.0;
  const ACTIVATION_THRESHOLD = 580.0;

  let scrollPosition = 0.0;
  let scrollingDown = true;
  let lastActivationPosition = 0.0;

  const isSticky = ref(false);
  const isActive = ref(false);

  const mobileNavActive = ref(false);

  // --

  onResize((width) => {
    const BREAKPOINT_SMALL = 768.0;
    mobileNavActive.value = mobileNavActive.value && width <= BREAKPOINT_SMALL;
  });

  onScroll((scrollX, scrollY) => {
    scrollingDown = scrollY >= scrollPosition;

    isSticky.value = (scrollingDown && scrollY > 0.0) || (!scrollingDown && scrollY > STICKY_TOP);
    isActive.value = (scrollingDown && scrollY < lastActivationPosition + ACTIVATION_THRESHOLD) || !scrollingDown;

    scrollingDown || (lastActivationPosition = scrollY);
    scrollPosition = scrollY;
  });

  // --

  onNavigate(() => {
    mobileNavActive.value = false;
  });

  // --

  function onHamburgerClick() {
    isActive.value = true;
    mobileNavActive.value = !mobileNavActive.value;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <header
    :class="{ isSticky: isSticky || mobileNavActive, isActive: (isActive || mobileNavActive) && !searchOverlayActive }"
  >
    <div class="container">
      <Wrapper class="desktop">
        <Nav />
      </Wrapper>

      <Wrapper class="mobile" wide>
        <div><Logo /><Hamburger :isActive="mobileNavActive" @click="onHamburgerClick" /></div>
      </Wrapper>
    </div>
    <MobileNav :isActive="mobileNavActive" />
  </header>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  header {
    position: fixed;
    width: 100%;

    z-index: $layer-header;
    pointer-events: none;

    transition: transform 0.52s $quadOut;
    transform: translate3d(0, 0, 0);

    &.isSticky {
      transform: translate3d(0, -$header-offset, 0);

      @include break-small {
        transform: translate3d(0, -$mobile-header-offset, 0);
      }
    }

    .light & {
      :deep(.router-link-active) {
        color: $grey-2;
      }

      :deep(path) {
        fill: black;
      }
    }
  }

  // .bar {
  //   width: 100%;
  //   height: $header-height;

  //   @include break-small {
  //     height: $mobile-header-height;
  //   }
  // }

  .container {
    position: relative;
    z-index: $layer-header;

    padding-top: $header-offset;
    overflow: hidden;

    @include break-phone {
      padding-top: $mobile-header-offset;
    }

    // hide
    transition: transform 0.45s $quadInOut;
    transform: translate3d(0, -100%, 0);

    .isActive & {
      // show
      transition: transform 0.45s $quadOut;
      transform: translate3d(0, 0, 0);
    }

    // bar
    &:before {
      content: '';

      position: absolute;
      top: 0;

      width: 100%;
      height: 100%;

      background-color: $black;

      .light & {
        background-color: $white;
      }

      // hide
      transition: opacity 0.25s $sineOut;
      opacity: 0;
    }

    .isSticky & {
      &:before {
        // show
        transition: opacity 0.17s $sineOut;
        opacity: 1;
      }
    }
  }

  .desktop {
    height: calc($header-height - $header-offset);

    display: flex;
    flex-direction: column;
    justify-content: center;

    @include break-small {
      display: none;
    }
  }

  .mobile {
    height: calc($mobile-header-height - $mobile-header-offset);
    padding-bottom: px(14);

    align-items: flex-end;
    display: none;

    @include break-small {
      display: flex;
    }

    > div {
      width: 100%;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
