<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <Header />
  <SearchOverlay />
  <NewsletterOverlay />
  <main><slot /></main>
  <Footer />
  <ClientOnly>
    <EventNotification />
  </ClientOnly>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  main {
    overflow: hidden;
    padding-bottom: $content-padding-bottom;
  }
</style>
