<script setup>
  const { searchOverlayActive } = useSearchOverlay();

  // --

  const searchQuery = ref('');
  provide('searchQuery', searchQuery);

  const input = ref();

  watch(searchOverlayActive, (value) => {
    if (value) {
      input.value.focus();
    }
  });

  async function search() {
    if (searchQuery.value === '') return;

    searchOverlayActive.value = false;

    await navigateTo({
      path: '/search',
      query: {
        q: searchQuery.value,
      },
    });

    setTimeout(() => {
      searchQuery.value = '';
    }, 1000.0);
  }

  function onBackgroundClick() {
    if (searchOverlayActive.value) searchOverlayActive.value = false;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="overlay" :class="{ active: searchOverlayActive }">
    <Grid wide>
      <form @submit.prevent="search">
        <SearchInput ref="input" @closeClick="searchOverlayActive = false" /></form
    ></Grid>
    <div class="background" @click="onBackgroundClick" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .overlay {
    position: fixed;
    pointer-events: none;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: $layer-overlay;

    .background {
      position: absolute;

      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      background: $black;
      opacity: 0;

      @include fade(opacity, 0.21s);
      z-index: -1;
    }

    .input {
      margin-top: px(14);
      pointer-events: none;

      transform: translate3d(0, px(-200), 0);
      transition: transform 0.38s $cubicInOut 0s;
    }

    &.active {
      pointer-events: auto;

      .input {
        pointer-events: auto;
        transform: translate3d(0, 0, 0);
      }

      .background {
        pointer-events: auto;
        opacity: 0.8;
      }
    }

    @include break-small {
      // display: none;
    }
  }
</style>
