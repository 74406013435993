<script setup>
  const store = useBaseStore();
  const { main, shop, tickets } = store.getNavBar;

  // --

  const ticketButton = ref();
  const { theme } = useTheme();

  onMounted(() => {
    setColor();
  });

  function setColor() {
    ticketButton.value?.changeColor(theme.value === 'light' ? 'black' : 'white');
  }

  watch(theme, () => {
    setColor();
  });

  // --

  const { searchOverlayActive, searchInputOnPage } = useSearchOverlay();

  // --

  function onSearchClick() {
    if (searchInputOnPage.value) {
      searchInputOnPage.value.focus();
    } else {
      searchOverlayActive.value = true;
    }
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <nav>
    <ul class="primary">
      <li v-if="main" v-for="link in main">
        <Logo v-if="link.url === '/'" class="logo" />
        <NavLink v-else :to="link.url">{{ link.title }}</NavLink>
      </li>
    </ul>

    <ul class="secondary">
      <li v-if="shop" class="shop">
        <span>
          <NavLink :to="shop.url">{{ shop.title }}</NavLink
          ><IconShopArrow />
        </span>
      </li>
      <li v-if="tickets" class="tickets">
        <Button ref="ticketButton" :to="tickets.url" outline>{{ tickets.title }}</Button>
      </li>
      <li class="search">
        <button @click="onSearchClick" aria-label="search">
          <IconSearch />
        </button>
      </li>
    </ul>
  </nav>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  nav {
    @include industry-demi(px(14));

    display: flex;
    justify-content: space-between;

    pointer-events: auto;

    ul {
      display: flex;
      align-items: center;

      .shop {
        span {
          position: relative;
          display: flex;
          gap: px(6);

          :deep(svg) {
            position: absolute;
            right: px(-12);

            margin-top: 0.55em;
          }
        }
      }

      .search {
        button {
          @include hitbox;

          @include fade('opacity', 0.62s);
          @include hover {
            @include fade('opacity', 0.19s);
            opacity: 0.85;
          }
        }
      }
    }
  }
</style>
