<script setup>
  const { newsletterOverlayActive } = useNewsletterOverlay();

  // --

  import { useForm } from 'laravel-precognition-vue';
  import moment from 'moment';

  const subscribeUrl = useRuntimeConfig().public.statamicApiUrl + '/newsletter';

  const form = useForm('post', subscribeUrl, {
    firstname: '',
    lastname: '',
    day: '',
    month: '',
    year: '',
    email: '',
  });

  const days = [];
  for (let i = 1; i <= 31; i++) {
    days.push(i);
  }

  const years = [];
  for (let j = moment().year() - 11; j >= 1900; j--) {
    years.push(j);
  }

  const consent = ref(false);
  const success = ref(false);
  const validation = ref('');

  const submit = () =>
    form
      .submit()
      .then((response) => {
        validation.value = '';
        success.value = true;
        form.reset();
      })
      .catch((error) => {
        validation.value = error.response.data.message;
      });

  // --

  function onCloseClick() {
    if (newsletterOverlayActive.value) {
      newsletterOverlayActive.value = false;
      form.reset();
      consent.value = false;
      success.value = false;
      validation.value = '';
    }
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="overlay" :class="{ active: newsletterOverlayActive }">
    <Grid full>
      <GridItem :from="4" :to="9">
        <div class="panel">
          <div class="close">
            <button @click="onCloseClick" aria-label="close">
              <IconClose />
            </button>
          </div>

          <form v-if="!success" @submit.prevent="submit" @keydown.enter="submit">
            <div>
              <h2>Newsletter</h2>
              <p>
                Be the first to receive priority access tickets, exclusive offers and the latest news about Glory events
                and fighters.
              </p>
            </div>
            <input
              :class="{ error: form.invalid('firstname') }"
              placeholder="First name"
              v-model="form.firstname"
              @change="form.validate('firstname')"
            />
            <input
              :class="{ error: form.invalid('lastname') }"
              placeholder="Last name"
              v-model="form.lastname"
              @change="form.validate('lastname')"
            />

            <input
              :class="{ error: form.invalid('email') }"
              placeholder="E-mail"
              type="email"
              v-model="form.email"
              @change="form.validate('email')"
            />

            <div class="date-of-birth">
              <h3>Date of Birth</h3>

              <div>
                <div class="select">
                  <IconArrowVertical />
                  <select :class="{ error: form.invalid('day') }" v-model="form.day" @change="form.validate('day')">
                    <option value="" selected disabled>Day</option>
                    <option :value="day" v-for="day in days">{{ day }}</option>
                  </select>
                </div>

                <div class="select">
                  <IconArrowVertical />
                  <select
                    :class="{ error: form.invalid('month') }"
                    v-model="form.month"
                    @change="form.validate('month')"
                  >
                    <option value="" selected disabled>Month</option>
                    <option :value="key + 1" v-for="(month, key) in moment.months()">{{ month }}</option>
                  </select>
                </div>

                <div class="select">
                  <IconArrowVertical />
                  <select :class="{ error: form.invalid('year') }" v-model="form.year" @change="form.validate('year')">
                    <option value="" selected disabled>Year</option>
                    <option :value="year" v-for="year in years">{{ year }}</option>
                  </select>
                </div>
              </div>
            </div>

            <div class="terms">
              <input type="checkbox" v-model="consent" />
              <p>I have read and agree with the GLORY <a href="/terms-of-use">Terms & Conditions</a>.</p>
            </div>

            <Button @click="submit" class="button" :class="{ disabled: form.processing || !consent }" to="javascript:;"
              >Sign up</Button
            >

            <span v-if="validation" class="validation-message">{{ validation }}</span>
          </form>

          <div v-else>
            <h2>Thank you</h2>
            <p>You have successfully signed up for the GLORY newsletter.</p>
            <Button outline color="white" @click="onCloseClick" class="button continue" to="javascript:;"
              >Continue</Button
            >
          </div>
        </div>
      </GridItem>
    </Grid>
    <div class="background" @click="onCloseClick" />
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .overlay {
    position: fixed;
    pointer-events: none;

    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    z-index: $layer-overlay;

    > div {
      height: 100%;
      pointer-events: none;

      :deep(.grid) {
        height: 100%;
        align-items: center;

        > div {
          @include break-max {
            @include column(3, 10);
          }

          @include break-medium {
            @include column(2, 11);
          }

          @include break-small {
            @include column(1, 12);
          }
        }
      }
    }

    .background {
      position: absolute;

      left: 0;
      top: 0;

      width: 100%;
      height: 100%;

      background: $black;
      opacity: 0;

      @include fade(opacity, 0.21s);
      z-index: -1;
    }

    .panel {
      position: relative;
      display: none;

      background-color: $black-2;
      padding: px(72) 7.5vw;

      @include break-small {
        padding: px(56) px(42);
      }

      .close {
        position: absolute;
        top: px(32);
        right: px(32);

        button {
          @include hitbox;

          :deep(path) {
            @include fade('fill', 0.12s);
          }

          @include hover {
            :deep(path) {
              fill: $red;
            }
          }
        }
      }

      h2 {
        @include title-l;
        color: $white;
      }

      p {
        margin-top: px(6);
        color: $white;

        a {
          text-decoration: underline;
          @include fade('color', 0.07s);
          @include hover {
            color: $red;
          }
        }
      }

      form {
        position: relative;

        display: flex;
        flex-direction: column;
        gap: px(40);
      }

      input:not([type='checkbox']),
      select {
        width: 100%;

        border-radius: px(2);
        height: px(44);

        padding: 0 px(16);
        font-size: px(14);

        border: none;
        outline: none;

        &::placeholder {
          color: $grey-3;
        }

        &.error {
          border: px(3) solid $red;
        }
      }

      .select {
        width: 100%;
        position: relative;

        display: flex;
        align-items: center;

        select {
          appearance: none;
          // color: $grey-3;
        }

        svg {
          position: absolute;

          right: 0;
          margin-right: px(10);
          margin-top: px(1);
        }
      }

      .terms {
        display: flex;
        align-items: center;
        gap: px(6);

        margin-top: -px(16);

        p {
          margin-top: 0;
        }
      }

      input[type='checkbox'] {
        accent-color: $red;

        width: px(16);
        height: px(16);
      }

      .date-of-birth {
        position: relative;

        > div {
          display: flex;
          gap: px(16);
        }

        h3 {
          position: absolute;
          @include roboto-regular(px(12));

          top: -px(16);
          color: $grey-3;
        }
      }

      .validation-message {
        position: absolute;
        bottom: -px(40);

        color: $red;
      }

      button:disabled {
        color: black;
        background: grey;
        cursor: default;
      }

      .button {
        align-self: flex-start;

        &.disabled {
          background: #3c0000;
          color: $grey-1;
          pointer-events: none;
        }

        &.continue {
          margin-top: px(40);
        }
      }
    }

    &.active {
      pointer-events: auto;

      .background {
        pointer-events: auto;
        opacity: 0.8;
      }

      .panel {
        display: block;
        pointer-events: auto;
      }
    }
  }
</style>
