<script setup></script>

<!----------------------------------------------------------------------------->

<template>
  <NuxtLink to="/">
    <IconGlory />
  </NuxtLink>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  a {
    pointer-events: auto;

    @include fade('opacity', 0.62s);
    @include hover {
      opacity: 0.85;
      @include fade('opacity', 0.19s);
    }

    svg {
      width: px(125);
      height: 100%;

      max-height: px(27);

      // --

      @include break-large {
        width: px(108);
      }

      @include break-medium {
        width: px(96);
      }

      @include break-small {
        width: px(90);
      }
    }
  }
</style>
