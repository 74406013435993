<script setup>
  import { statamicRequest } from '~/pinkyellow/satanic-api';
  import moment from 'moment';

  const channels = ref([]);
  const notification = ref(false);
  const dismissed = ref(false);
  const notEnded = ref(false);
  //   useCookie('dismissed-ppv-notification', {
  //   default: () => false,
  //   maxAge: 60 * 60 * 24,
  // });

  const data = await statamicRequest({ uri: 'ppv-notification' });

  if (data) {
    notification.value = data;
    notEnded.value = moment(data.event.ends_at).isAfter(moment.now());

    const channelStore = usePpvChannelsStore();
    await channelStore.fetch();

    channels.value = channelStore.getChannels(useGuessCountry());
  }

  function dismiss() {
    dismissed.value = true;
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <div class="notification" v-if="notification && !dismissed && notEnded" :key="useGuessCountry">
    <button class="close" @click="dismiss"><IconClose /></button>
    <h2>WATCH</h2>
    <EventLogo :event="notification.event" tiny class="logo" />
    Live at {{ useDate().formatTime(notification.event.starts_at) }} {{ useDate().guessTimezone() }}
    <ul>
      <li v-for="channel in channels">
        <Button v-if="channel.coming_soon" class="disabled"> Coming soon</Button>
        <Button v-else :to="channel.link">
          <img :src="channel.logo" :alt="channel.name" width="120" height="54" />
        </Button>
        <span class="subtitle" v-if="channel.subtitle">{{ channel.subtitle }}</span>
      </li>
    </ul>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .notification {
    position: fixed;
    z-index: $layer-notification;

    filter: drop-shadow(0px 4px 12px rgba(0, 0, 0, 0.1));

    bottom: 0;
    right: 0;

    background-color: $black-2;
    border: 1px solid $black-5;

    margin: 18px;
    padding: px(20) px(15) px(15);

    @include industry-demi(px(12));

    width: px(308);

    display: flex;
    flex-direction: column;
    align-items: center;

    .close {
      position: absolute;

      right: 0;
      top: 0;

      padding: px(12);

      :deep(svg) {
        width: px(12);
        path {
          fill: $white-40;
        }
      }

      @include hover {
        :deep(svg) {
          path {
            fill: $white;
          }
        }
      }
    }

    h2 {
      @include heroic-bold(px(32));
    }

    .logo {
      margin-top: px(20);
    }

    ul {
      display: flex;
      width: 100%;
      margin-top: px(24);

      gap: px(10);

      li {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;

        a {
          padding: 0 1rem;
          overflow: hidden;

          width: 100%;

          :deep(span) {
            max-width: px(120);
          }
        }

        .subtitle {
          margin-top: px(8);

          color: $grey-1;

          text-align: center;
        }
      }
    }

    @include break-small {
      width: 100%;
      width: calc(100% - 36px);
    }
  }
</style>
