<template>
  <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M18 0H0V2H18V0ZM0 6H18V8H0V6ZM0 12H18V14H0V12Z" fill="white" />
  </svg>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  svg {
    width: px(18);
  }
</style>
