<script setup>
  const { to } = defineProps({
    to: String,
    small: Boolean,
    medium: Boolean,
    left: Boolean,
    black: Boolean,
    white: Boolean,
    disabled: Boolean,
    noAnchor: Boolean,
  });
  const target = useLinkTarget(to);

  // --

  function onClick() {
    window.scrollTo(0.0, 0.0);
  }
</script>

<!----------------------------------------------------------------------------->

<template>
  <button @click="onClick" aria-label="back to top">
    <IconArrowVertical />
  </button>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: px(40);
    height: px(40);

    border: px(1) solid $grey-1;
    border-radius: 100%;

    cursor: pointer;

    @include fade('border-color', 0.15s);

    :deep(svg) {
      transform: scaleY(-1);
      margin-top: -px(1);
      path {
        fill: $white;
        @include fade('fill', 0.15s);
      }
    }

    @include hover {
      border-color: $white;
      @include fade('border-color', 0.05s);

      :deep(svg) {
        path {
          fill: $white;
          @include fade('fill', 0.05s);
        }
      }
    }

    @include break-small {
      // display: none;
    }
  }
</style>
